/* CircularStd @font-face kit */
@font-face {
  font-family: "CircularStd";
  src: url(/static/media/CircularStd-Black.faf6412f.eot);
  src: url(/static/media/CircularStd-Black.faf6412f.eot?#iefix) format("embedded-opentype"), url(/static/media/CircularStd-Black.59880bb7.woff) format("woff"), url(/static/media/CircularStd-Black.c885948f.ttf) format("truetype"), url(/static/media/CircularStd-Black.1b076d9a.svg#bcc26993292869431e54c666aafa8fcd) format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "CircularStd";
  src: url(/static/media/CircularStd-BlackItalic.2d00dacf.eot);
  src: url(/static/media/CircularStd-BlackItalic.2d00dacf.eot?#iefix) format("embedded-opentype"), url(/static/media/CircularStd-BlackItalic.e13b5c7b.woff) format("woff"), url(/static/media/CircularStd-BlackItalic.7f645a71.ttf) format("truetype"), url(/static/media/CircularStd-BlackItalic.5bbbdfee.svg#bcc26993292869431e54c666aafa8fcd) format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "CircularStd";
  src: url(/static/media/CircularStd-Bold.e66cd439.eot);
  src: url(/static/media/CircularStd-Bold.e66cd439.eot?#iefix) format("embedded-opentype"), url(/static/media/CircularStd-Bold.1ced22ee.woff) format("woff"), url(/static/media/CircularStd-Bold.ce2a6c41.ttf) format("truetype"), url(/static/media/CircularStd-Bold.bec46313.svg#bcc26993292869431e54c666aafa8fcd) format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "CircularStd";
  src: url(/static/media/CircularStd-BoldItalic.e714c355.eot);
  src: url(/static/media/CircularStd-BoldItalic.e714c355.eot?#iefix) format("embedded-opentype"), url(/static/media/CircularStd-BoldItalic.8f682a90.woff) format("woff"), url(/static/media/CircularStd-BoldItalic.fe0bddb8.ttf) format("truetype"), url(/static/media/CircularStd-BoldItalic.a15d13a6.svg#bcc26993292869431e54c666aafa8fcd) format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "CircularStd";
  src: url(/static/media/CircularStd-Medium.3473fea8.eot);
  src: url(/static/media/CircularStd-Medium.3473fea8.eot?#iefix) format("embedded-opentype"), url(/static/media/CircularStd-Medium.bb9a359a.woff) format("woff"), url(/static/media/CircularStd-Medium.46d551df.ttf) format("truetype"), url(/static/media/CircularStd-Medium.1cb26577.svg#bcc26993292869431e54c666aafa8fcd) format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "CircularStd";
  src: url(/static/media/CircularStd-MediumItalic.1e3f8d0e.eot);
  src: url(/static/media/CircularStd-MediumItalic.1e3f8d0e.eot?#iefix) format("embedded-opentype"), url(/static/media/CircularStd-MediumItalic.21a7520d.woff) format("woff"), url(/static/media/CircularStd-MediumItalic.7095d23a.ttf) format("truetype"), url(/static/media/CircularStd-MediumItalic.0a998a32.svg#bcc26993292869431e54c666aafa8fcd) format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "CircularStd";
  src: url(/static/media/CircularStd-Book.d246520d.eot);
  src: url(/static/media/CircularStd-Book.d246520d.eot?#iefix) format("embedded-opentype"), url(/static/media/CircularStd-Book.ed76eb21.woff) format("woff"), url(/static/media/CircularStd-Book.c43e9feb.ttf) format("truetype"), url(/static/media/CircularStd-Book.25577b4f.svg#bcc26993292869431e54c666aafa8fcd) format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "CircularStd";
  src: url(/static/media/CircularStd-BookItalic.6d37d850.eot);
  src: url(/static/media/CircularStd-BookItalic.6d37d850.eot?#iefix) format("embedded-opentype"), url(/static/media/CircularStd-BookItalic.61889cfb.woff) format("woff"), url(/static/media/CircularStd-BookItalic.77a3e2ba.ttf) format("truetype"), url(/static/media/CircularStd-BookItalic.82b115ef.svg#bcc26993292869431e54c666aafa8fcd) format("svg");
  font-weight: 400;
  font-style: italic;
}

